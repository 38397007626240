import React, { useState, useEffect } from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate, useLocation } from "react-router-dom";
import Signin from "../pages/Auth/Sign-in/Signin";
import Signup from "../pages/Auth/Sign-up/Signup";
import { modalManage } from "../redux/slices/authModalHandleSlice";
import { Button } from "@mui/material";
import { ClearAuthSign } from "../redux/slices/authSlice";
import { clearAllproviderSearchCardTabChange } from "../redux/slices/providerCardSlice";
import { clearAllSearchCardAfterTabChange } from "../redux/slices/seekerCardSlice";
import { getCookie, removeCookie } from "../config/cookies";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ScrollToTop from "./ScrollToTop";
import SignupPopup from '../pages/Auth/Sign-up/SignupPopup'; // Import the new component

function NavList() {
  const navigate = useNavigate();
  const location = useLocation();

  const onTextClick = (path) => {
    navigate(path);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className="flex flex-col items-center gap-4">
      <List className="self-stretch flex flex-col items-center justify-center gap-[5px] py-2 text-darkslateblue-100 mx-5 lg:text-white font-raleway lg:my-0 lg:mx-0 lg:flex-row lg:p-1 lg:gap-[10px]">
        {[
          { name: "Home", path: "/home" },
          { name: "Resource", path: "/resourceavailable" },
          { name: "Opportunity", path: "/resourcerequire" },
          { name: "Assessment", path: "/assesement" },
          { name: "Pricing", path: "/price" },
          { name: "FAQs", path: "/faq" },
        ].map(({ name, path }, index) => (
          <Typography
            key={index}
            className={`cursor-pointer text-xl py-0 relative group ${
              isActive(path) ? "font-black text-current" : ""
            }`}
            onClick={() => onTextClick(path)}
          >
            <ListItem className="flex items-center py-2 px-0.5 relative group-hover:font-black">
              {name}
              <span className="absolute bottom-0 left-0 w-0 h-[1px] bg-current transition-all duration-300 group-hover:w-full"></span>
            </ListItem>
          </Typography>
        ))}
      </List>
    </div>
  );
}

const Navbar2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location/path
  const [openNav, setOpenNav] = useState(false);
  const [navbarTransparent, setNavbarTransparent] = useState(true);
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0); // To track the last scroll position
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 799);
  const [signupPopupOpen, setSignupPopupOpen] = useState(false); // Add state for signup popup

  const navigation = () => {
    if (_.get(userDetail, "user.is_provider", false)) {
      navigate("/provider");
    } else {
      navigate("/seeker");
    }
  };

  const handleLoginShow = () => {
    navigate("/loginpage");
  };

  const token = getCookie("authToken");

  const userDetail = useSelector((state) => _.get(state, "reducer.auth", {}));
  const handleLogOut = () => {
    window.location.href = "/";
    removeCookie("authToken");
    removeCookie("isSeeker");
    removeCookie("isProvider");
    removeCookie("expiresIn");
    dispatch(ClearAuthSign());
  };

  const clearCard = () => {
    dispatch(clearAllSearchCardAfterTabChange());
    dispatch(clearAllproviderSearchCardTabChange());
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
  
    // Check if the current path is `/provider` or `/seeker`
    const isFixedPath = location.pathname.includes("/provider") || location.pathname.includes("/seeker");
  
    if (isFixedPath) {
      // For these paths, always show the navbar and don't adjust transparency
      setNavbarVisible(true);
      setNavbarTransparent(false);
    } else {
      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        // Scrolling down and past a certain threshold
        setNavbarVisible(false); // Hide navbar
      } else if (currentScrollY < lastScrollY) {
        // Scrolling up
        setNavbarVisible(true); // Show navbar
      }
  
      setNavbarTransparent(currentScrollY <= 50);
    }
  
    setLastScrollY(currentScrollY); // Update the last scroll position
  };
  

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", () => {
      setIsMobileView(window.innerWidth <= 799);
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", () => {
        setIsMobileView(window.innerWidth <= 799);
      });
    };
  }, [lastScrollY]); // Add lastScrollY as a dependency to re-trigger effect on scroll

  // Modify navbarStyle based on the current location and screen size
  const navbarStyle = {
    boxShadow: "none",
    backgroundColor: isMobileView
      ? "white" // Always white for mobile view
      : location.pathname.includes('/provider') || location.pathname.includes('/seeker') 
      ? "#015498"  // Opaque on provider or seeker page
      : navbarTransparent
      ? "transparent"
      : "#015498",
    position: isMobileView
      ? "fixed" // Fixed on top for mobile view
      : "fixed",
    top: 0,
    width: "100%", // Ensure it spans the full width
    zIndex: 50,
    transform: navbarVisible ? "translateY(0)" : "translateY(-100%)", // Apply the hiding effect
    transition: "transform 0.3s ease", // Smooth transition
  };

  // Adjust the main content's top padding to make space for the fixed navbar
  const contentStyle = {
    paddingTop: isMobileView ? "90px" : "0px", // Adjust the padding to the height of the navbar (approximately 80px)
  };

  return (
    <>
      <ScrollToTop />
      <Navbar
        className={`w-full rounded-0xl z-50 transition-colors ${
          navbarTransparent
            ? isMobileView
              ? "bg-transparent"
              : "bg-transparent"
            : isMobileView
            ? "bg-white"
            : "bg-blue-500"
        } ${isMobileView ? "pl-0 pr-3 pt-2" : "pl-36 pr-44 pt-0"}`}
        style={navbarStyle}
      >
        <div className="flex items-center justify-between text-black">
          <div className="self-stretch w-[auto] rounded-t-none rounded-b-xl bg-white flex flex-col items-center justify-center px-5 pt-5 pb-2 lg:py-5 lg:px-5 box-border">
            <img
              className="relative max-w-[150px] lg:py-5 max-h-[100px] overflow-hidden object-cover"
              loading="lazy"
              alt=""
              src="/image-64@2x.png"
            />
          </div>
          <div className={`hidden lg:block ${isMobileView ? "hidden" : ""}`}>
            <NavList />
          </div>
          <div className={`hidden gap-2 lg:flex text-white ${isMobileView ? "flex" : ""}`}>
            {token ? (
              <>
                <Button
                  variant="button"
                  className="bg-darkslateblue-100 text-xl py-3"
                  onClick={() => navigation()}
                >
                  DASHBOARD
                </Button>
                <Button
                  variant="button"
                  className="bg-forestgreen text-xl py-3"
                  onClick={handleLogOut}
                >
                  LOGOUT
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="button"
                  className="bg-darkslateblue-100 py-1 text-3xl font-raleway font-bold hover:bg-forestgreen"
                  onClick={handleLoginShow}
                >
                  LOGIN
                </Button>
                <Button
                  variant="button"
                  className="bg-forestgreen py-1 text-3xl font-raleway font-bold hover:bg-darkslateblue-100"
                  onClick={() => setSignupPopupOpen(true)} // Open signup popup
                >
                  SIGN UP
                </Button>
              </>
            )}
          </div>
          <div className="lg:hidden">
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <XMarkIcon className="h-8 w-8 text-blue-500" />
              ) : (
                <Bars3Icon className="h-8 w-8 text-blue-500" />
              )}
            </IconButton>
          </div>
        </div>
        <Collapse open={openNav}>
          <NavList />
          <div className="flex w-full flex-nowrap gap-2 lg:hidden">
            {token ? (
              <>
                <Button
                  variant="button"
                  className="bg-darkslateblue-100 text-xl py-3 w-1/2"
                  onClick={() => navigation()}
                >
                  DASHBOARD
                </Button>
                <Button
                  variant="button"
                  className="bg-forestgreen text-xl py-3 w-1/2"
                  onClick={handleLogOut}
                >
                  LOGOUT
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="button"
                  className="bg-darkslateblue-100 py-1 text-3xl font-raleway font-bold w-1/2 hover:bg-forestgreen"
                  onClick={handleLoginShow}
                >
                  LOGIN
                </Button>
                <Button
                  variant="button"
                  className="bg-forestgreen py-1 text-3xl font-raleway font-bold w-1/2 hover:bg-darkslateblue-100"
                  onClick={() => setSignupPopupOpen(true)} // Open signup popup
                >
                  SIGN UP
                </Button>
              </>
            )}
          </div>
        </Collapse>
        
      </Navbar>

      {/* Main Content */}
      <div style={contentStyle}>
        <Signin />
        <SignupPopup open={signupPopupOpen} onClose={() => setSignupPopupOpen(false)} />
      </div>
    </>
  );
};

export default Navbar2;
